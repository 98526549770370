import React, { useState, useEffect } from "react";
import { styled, ThemeProvider, DarkTheme } from "baseui";
import { StatefulPopover } from "baseui/popover";
import { Slider } from "baseui/slider";
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { Input } from "baseui/input";
import { jsPDF } from "jspdf";
import useAppContext from "../../../../hooks/useAppContext";
import Icons from "../../icons";
import Cookies from "universal-cookie";
import PaymentModal from "../Modal";

const Container = styled("div", (props) => ({
  height: "36px",
  background: "#424242",
  borderBottom: "0.5px solid rgb(169,169,169)",
  display: "flex",
  padding: "0 1rem",
  justifyContent: "space-between",
  alignItems: "center",
  overflow: "hidden",
}));

function Navbar({ openAboutModal, showStripeModal, showAccount, setStripeModalVisible, setAccountVisible }) {
  const { editor, page, setPage, setTemplate, zoom } = useAppContext();

  const [zoomValue, setZoomValue] = useState([5]);
  const [showPayModal, setShowPayModal] = useState(false);
  const [fileName, setFileName] = useState("");
  const [availableDownload, setAvailableDownload] = useState(false);
  const [download, setDownload] = useState(0);

  const setBackgroundImage = (path) => {
    setZoomValue([100]);
    editor.setBackgroundImage(path);
    editor.sendToBack();
    editor.discardActiveSelection();
  };

  const setZoom = (value) => {
    setZoomValue(value);
    editor.setBackgroundImageZoom(value[0]);
    editor.sendToBack();
    editor.discardActiveSelection();
  };

  useEffect(() => {
    if (editor) {
      editor.setBackgroundImageZoom([100]);
      editor.sendToBack();


      setZoomValue([editor.getZoom()]);
    }
  }, [editor]);




  const generateUUID = (ext) => {
    return `xxxxxxxx.${ext}`.replace(/[x]/g, (c) => {
      let r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };
  const downloadFile = (type, string, ext) => {
    let blob = new Blob([string], {
      type: type,
    });
    let url = URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.href = url;
    link.download = `${fileName || "my Logo"}.${ext}`; //generateUUID(ext);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const importTemplate = async () => {
    let fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".sym";
    fileInput.onchange = (event) => {
      let reader = new FileReader();
      reader.onload = (e) => {
        let template = JSON.parse(e.target.result);
        if (page !== "Editor") {
          setPage("Editor");
          setTemplate(template);
        } else {
          editor.importTemplate(template);
        }
      };
      reader.readAsText(fileInput.files[0]);
    };
    fileInput.click();
  };
  const exportTemplate = () => {
    if (sessionStorage.getItem("isPaid")) {
      downloadFile("application/json", editor.exportTemplate(), "sym");
    } else {
      setShowPayModal(true)
    }
  };

  const downloadImage = (type, resolution, background) => {
    if (sessionStorage.getItem("isPaid")) {
      resolution = resolution === "low" ? 125 : 250;
      if (type === "png") {
        let link = document.createElement("a");
        link.href = editor.exportPng(resolution, background);
        link.download = `${fileName || "logoCentrix"}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      if (type === "svg") {
        downloadFile("image/svg+xml;charset=utf-8", editor.exportSvg(), "svg");
      }
      if (type === "pdf") {
        let pdf = new jsPDF("p", "mm", "a4");
        let imgData = editor.exportPng(500, background);

        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'svg', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`${fileName || "logoCentrix"}.pdf`);
      }
    } else {
      setShowPayModal(true)
    }
  };

  useEffect(() => {
    if (!availableDownload) return;
    if (download == 0) {
      exportTemplate();
    } else if (download == 1) {
      downloadImage("png", "low", false, false);
    } else if (download == 2) {
      downloadImage("png", "low", true, true);
    } else if (download == 3) {
      downloadImage("png", "high", false, false);
    } else if (download == 4) {
      downloadImage("png", "high", true, true);
    } else if (download == 5) {
      downloadImage("svg");
    } else {
      downloadImage("pdf", "", true);
    }
  }, [availableDownload]);


  const setPageWithoutConfirm = (page) => {
    setPage(page);
  };

  const undo = () => {
    if (editor) editor.undo();
  };

  const redo = () => {
    if (editor) editor.redo();
  };

  const updateZoom = (value) => {
    setZoomValue(value);
    editor.setZoom(value[0]);
  };

  const updateZoomPlus = () => {
    if (zoomValue<3.9)
    {let value = editor.getZoom() + 0.1;
    setZoomValue([value]);
    editor.setZoom(value);}

  };
  const updateZoomMinus = () => {
    if (zoomValue>0.2)
    {let value = editor.getZoom() - 0.1;
    setZoomValue([value]);
    editor.setZoom(value);}

  };

  const fitToScreen = () => {
    editor.fitToScreen();
  };

  return (
    <ThemeProvider theme={DarkTheme}>
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: "#424242",
            width: "100%",



          }}
        >
          <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: "#fff",
                fontFamily: "system-ui",
                fontSize: "10pt",
                marginLeft: "0px",
                marginRight: "10px",

              }}
            >

            </div>




            <Button
              onClick={() => {
                setPageWithoutConfirm("Templates");
              }}
              size={SIZE.mini}
              kind={KIND.tertiary}
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => ({

                    color: "#D6D6D6",
                  }),
                },
              }}
            >
              Collection
            </Button>


            <Button
              onClick={importTemplate}
              size={SIZE.mini}
              kind={KIND.tertiary}
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => ({

                    color: "#D6D6D6",
                  }),
                },
              }}
            >
              Open
            </Button>
            <Button onClick={undo} size={SIZE.mini} kind={KIND.tertiary}
            overrides={{
              BaseButton: {
                style: ({ $theme }) => ({

                  color: "#D6D6D6",
                }),
              },
            }}>

              Undo
            </Button>
            <Button onClick={redo} size={SIZE.mini} kind={KIND.tertiary}
            overrides={{
              BaseButton: {
                style: ({ $theme }) => ({

                  color: "#D6D6D6",
                }),
              },
            }}>
              Redo
            </Button>
            <StatefulPopover
              content={({ close }) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100vw",
                    textAlign: "center",
                    background: "#424242",
                    padding: "10px",
                    fontFamily: "system-ui",
                    maxWidth: "600px",
                    border: "solid 0.5px #A9A9A9",
                    color: '#000'
                  }}
                >
                  <div
                    style={{
                      alignSelf: 'flex-end',
                      cursor: "pointer"
                    }}
                    onClick={() => close()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="#fff"
                      className="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </div>
                  <div
                    style={{
                      fontWeight: "normal",
                      fontSize: "10pt",
                      textAlign: "left",
                      padding: "0 20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                        marginTop: "20px",
                      }}
                    >
                      <p style={{ fontSize: "12pt", margin: "0px", fontFamily: "system-ui", color:"#F2F2F2", fontWeight: 'bold' }}>Download as...</p>
                      <div style={{ width: "40%", marginLeft: "25px", background: "#fff" }}>
                        <Input
                          style={{ backgroundColor: '#fff' }}
                          size={SIZE.compact}
                          value={fileName}
                          onChange={(e) => setFileName(e.target.value)}
                          placeholder="my Logo"
                          clearOnEscape
                        />
                      </div>
                    </div>


                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "20px 0px 10px 0px",
                      color:"#E7E7E7",
                      fontFamily: "system-ui"
                    }}>  <br />
                      <br /></div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <p style={{ fontSize: "10pt", fontWeight: "bold", color:"#F2F2F2",fontFamily: "system-ui" }}>
                        1. SYM file, for later editing directly in your browser.
                      </p>
                      <Button
                        onClick={() => {
                          setDownload(0);
                          exportTemplate();
                        }}
                        size={SIZE.large}
                        shape={SHAPE.pill}
                        //disabled={!isSendFeedback}
                        overrides={{
                          BaseButton: {
                            style: ({ $theme }) => ({
                              backgroundColor: "#000",
                              color: "#A9A9A9",
                              fontSize: "10pt",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              border: "solid 1px #A9A9A9",
                            }),
                          },
                        }}
                      >
                        Download
                      </Button>
                    </div>



                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <p style={{ fontSize: "10pt", color:"#F2F2F2"}}>
                        2. PNG image file with transparent background.
                      </p>
                      <Button
                        onClick={(event) => {
                          setDownload(3);
                          downloadImage("png", "high", false, false);
                        }}
                        size={SIZE.large}
                        shape={SHAPE.pill}
                        //disabled={!isSendFeedback}
                        overrides={{
                          BaseButton: {
                            style: ({ $theme }) => ({
                              backgroundColor: "#000",
                              color: "#A9A9A9",
                              fontSize: "10pt",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              border: "solid 1px #A9A9A9",
                            }),
                          },
                        }}
                      >
                        Download
                      </Button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <p style={{ fontSize: "10pt", color:"#F2F2F2" }}>
                        3. PNG image file with chosen background color.
                      </p>
                      <Button
                        onClick={(event) => {
                          setDownload(4);
                          downloadImage("png", "high", true, true);
                        }}
                        size={SIZE.large}
                        shape={SHAPE.pill}
                        //disabled={!isSendFeedback}
                        overrides={{
                          BaseButton: {
                            style: ({ $theme }) => ({
                              backgroundColor: "#000",
                              color: "#A9A9A9",
                              fontSize: "10pt",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              border: "solid 1px #A9A9A9",
                            }),
                          },
                        }}
                      >
                        Download
                      </Button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "20px",
                      }}
                    >
                      <p style={{ fontSize: "10pt", color:"#F2F2F2" }}>
                        4. SVG file - vector file for Adobe Illustrator, Affinity Designer, etc.
                      </p>
                      <Button
                        onClick={(event) => {
                          setDownload(5);
                          downloadImage("svg");
                        }}
                        size={SIZE.large}
                        shape={SHAPE.pill}
                        //disabled={!isSendFeedback}
                        overrides={{
                          BaseButton: {
                            style: ({ $theme }) => ({
                              backgroundColor: "#000",
                              color: "#A9A9A9",
                              fontSize: "10pt",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              border: "solid 1px #A9A9A9",
                            }),
                          },
                        }}
                      >
                        Download
                      </Button>
                    </div>

                  </div>







                  <div style={{
                    margin: '0 20px',
                    height: '1px',
                    minHeight: '1px',
                    maxHeight: '1px',
                    background: '#000000'
                  }} />
                  <div style={{ padding: '0 20px' }}>
                    <div style={{
                      fontSize: "10pt",
                      fontWeight: "bold",
                      textAlign: 'start',
                      fontFamily: "system-ui",
                      lineHeight: '2rem',
                      color: '#FF5D40'
                    }}>IMPORTANT:</div>
                    <div style={{
                      fontSize: "10pt",
                      textAlign: 'start',
                      fontFamily: "system-ui",
                      lineHeight: '1.6rem',
                      color:"#F2F2F2"

                    }}>
                    Setup your logo inside the t-shirt from the mockup area, before download.
                    <br />
                    Scaling your logo bigger then the mockup area can generate errors.
                    <br />
                    <br />
                    The SVG file is a vector file, scalable on any size.
                    <br />
                    If your design contains stroke elements the SVG file will keep the stroke properties.
                    <br />
                    After you import it into other software, select the stroke elements and reset the width.
                    <br />
                    If you need any assistance on how to use the SVG files, please contact us.
                    <br />
                    <br />
                    </div>
                  </div>
                </div>
              )}
              returnFocus
              autoFocus
              dismissOnClickOutside={showStripeModal ? false : true}
              dismissOnEsc={showStripeModal ? false : true}
            >
              <Button size={SIZE.mini} kind={KIND.tertiary}
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => ({

                    color: "#D6D6D6",
                  }),
                },
              }}>
                Download
              </Button>
            </StatefulPopover>

            <Button
              onClick={() => {
                window.open("/help.html", "_blank");
              }}
              size={SIZE.mini}
              kind={KIND.tertiary}
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => ({

                    color: "#D6D6D6",
                  }),
                },
              }}
            >
              Help
            </Button>

            <Button
              onClick={() => openAboutModal()}
              size={SIZE.mini}
              kind={KIND.tertiary}
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => ({

                    color: "#D6D6D6",
                  }),
                },
              }}
            >
              About
            </Button>

          </div>
          <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>



            <div
              style={{
                justifySelf: "end",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: "#fff",
                //background: "rgb(0,0,0)",
                fontFamily: "system-ui",
                fontSize: "10pt",
                marginLeft: "10px",
                marginRight: "380px",

              }}
            >
            <Button

            style=
            {{
              marginRight: "10px",
            }}
            onClick={fitToScreen}
              size={SIZE.mini}
              kind={KIND.tertiary}
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => ({

                    color: "#D6D6D6",
                  }),
                },
              }}
            >
              Fit to Screen
            </Button>






              <StatefulPopover
                content={({ close }) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100vw",
                      textAlign: "center",
                      background: "rgb(0,0,0)",
                      padding: "10px",
                      fontFamily: "system-ui",
                      fontWeight: "bold",
                      maxWidth: "300px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        fontSize: "16px",
                        fontWeight: 500,
                        fontFamily: "system-ui",
                      }}
                    >

                      <Slider
                        overrides={{
                          InnerThumb: () => null,
                          ThumbValue: () => null,
                          TickBar: () => null,
                          Thumb: {
                            style: {
                              height: "20px",
                              width: "20px",
                            },
                          },
                        }}
                        min={0.1}
                        max={4}
                        step={0.001}
                        marks={false}
                        value={zoomValue}
                        onChange={({ value }) => value && updateZoom(value)}
                      />

                    </div>
                  </div>
                )}
                returnFocus
                autoFocus
                placement="bottomRight"
              >

                <Button
                style=
                {{
                  marginRight: "10px",
                }}
                size={SIZE.mini} kind={KIND.tertiary}
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => ({

                      color: "#D6D6D6",
                    }),
                  },
                }}>
                  Zoom
                </Button>
              </StatefulPopover>



              <Button
                onClick={updateZoomPlus}
                size={SIZE.mini}
                kind={KIND.tertiary}
              >
                <Icons.ZoomPlus size={22} />
              </Button>

              <Button
                onClick={updateZoomMinus}
                size={SIZE.mini}
                kind={KIND.tertiary}
              >
                <Icons.ZoomMinus size={22} />
              </Button>





            </div>
          </div>
        </div>
      </Container>
      <PaymentModal
        setAvailableDownload={setAvailableDownload}
        showPayModal={showPayModal}
        setShowPayModal={setShowPayModal} />
    </ThemeProvider>
  );
}

export default Navbar;
