import React, { useEffect, useState } from "react";
import useAppContext from "../../../../../hooks/useAppContext";

import { Button, KIND, SIZE } from "baseui/button";
import { Slider } from "baseui/slider";
import { Select } from "baseui/select";

//import Icons from "../../../icons";
import Fonts from "../../../../../constants/fonts";
const colorsList = [
  "#000000",
  "#212121",
  "#424242",
  "#5e5e5e",
  "#797979",
  "#919191",
  "#929292",
  "#a9a9a9",
  "#c0c0c0",
  "#cecece",
  "#ebebeb",
  "#fff",
  // end row
  "#941100",
  "#945200",
  "#929000",
  "#4f8f00",
  "#008f00",
  "#009051",
  "#009193",
  "#005493",
  "#011993",
  "#531b93",
  "#942193",
  "#941751",
  // end row
  "#ff2600",
  "#ff9300",
  "#fffb00",
  "#8efa00",
  "#00f900",
  "#00fa92",
  "#00fdff",
  "#0096ff",
  "#0433ff",
  "#9437ff",
  "#ff40ff",
  "#ff2f92",
  // end row
  "#ff7e79",
  "#ffd479",
  "#fffc79",
  "#d4fb79",
  "#73fa79",
  "#73fcd6",
  "#73fdff",
  "#76d6ff",
  "#7a81ff",
  "#d783ff",
  "#ff85ff",
  "#ff8ad8",
];
function Text() {
  const { activeSelection, editor, isMobile } = useAppContext();
  const [editingModeValue, setEditingModeValue] = useState(false);
  const [fontValue, setFontValue] = useState([]);
  const [spacingValue, setSpacingValue] = useState([0]);
  const [logoName, setLogoName] = useState('');


  useEffect(() => {
    if (activeSelection) {
      setEditingModeValue(activeSelection.editingMode);
      setFontValue([{ fontFamily: activeSelection.fontFamily }]);
      setSpacingValue([activeSelection.spacing]);
      setLogoName(activeSelection.content);
    }
  }, [activeSelection]);

  const setFont = (font) => {
    setFontValue(font);
    if (editor && font && font[0]) {
      editor.loadFont(font[0].fontFamily, () => {
        editor.update({ fontFamily: font[0].fontFamily });
      });
    }
  };

  const setEditing = () => {
    let isEditing = editor.isEditing();
    isEditing ? editor.exitEditingMode() : editor.enterEditingMode();
    setEditingModeValue(!isEditing);
    //editor.setEditingModeAction(null);
  };

  const changeLogoName = (event) => {
    setLogoName(event.target.value);
    editor.setTextContent(event.target.value);
  }

  const addText = () => {
    editor.addText();
  };

  const setSpacing = (value) => {
    setSpacingValue(value);
    editor.update({ spacing: value[0] });
  };


  const setFill = (color) => {
    editor.update({ fillColor: color });
  };

  const bringToFront = () => {
    editor.bringToFront();
  };

  const sendToBack = () => {
    editor.sendToBack();
  };

  const removeActiveSelection = () => {
    //let confirm = window.confirm("Delete this text?");
    //if (confirm)
    editor.removeActiveSelection();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        height: isMobile ? "360px" : "100%",
        color: "#D6D6D6"
      }}
    >
      <div
        style={{
          padding: "10px",
          display:
            activeSelection && activeSelection._class === "PathText"
              ? "block"
              : "none",
          opacity: editor && editor.isEditing() ? 0.3 : 1,
          pointerEvents: editor && editor.isEditing() ? "none" : "auto",
        }}
      >
        <div
          style={{
            display: activeSelection ? "flex" : "none",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <Button onClick={bringToFront} size={SIZE.mini} kind={KIND.primary}
          overrides={{
            BaseButton: {
              style: ({ $theme }) => ({
                borderColor: "grey",
                borderLeftWidth: "1px",
                borderRightWidth: "1px",
                borderTopWidth: "1px",
                borderBottomWidth: "1px",
                borderLeftStyle: "solid",
                borderRightStyle: "solid",
                borderTopStyle: "solid",
                borderBottomStyle: "solid",
                color: "#D6D6D6",
                backgroundColor: "#424242"
              }),
            },
          }}
          >
            To Front
          </Button>
          <Button onClick={sendToBack} size={SIZE.mini} kind={KIND.primary}
          overrides={{
            BaseButton: {
              style: ({ $theme }) => ({
                borderColor: "grey",
                borderLeftWidth: "1px",
                borderRightWidth: "1px",
                borderTopWidth: "1px",
                borderBottomWidth: "1px",
                borderLeftStyle: "solid",
                borderRightStyle: "solid",
                borderTopStyle: "solid",
                borderBottomStyle: "solid",
                color: "#D6D6D6",
                backgroundColor: "#424242"
              }),
            },
          }}
          >
            To Back
          </Button>
          <Button
            onClick={removeActiveSelection}
            size={SIZE.mini}
            kind={KIND.primary}
            overrides={{
              BaseButton: {
                style: ({ $theme }) => ({
                  borderColor: "grey",
                  borderLeftWidth: "1px",
                  borderRightWidth: "1px",
                  borderTopWidth: "1px",
                  borderBottomWidth: "1px",
                  borderLeftStyle: "solid",
                  borderRightStyle: "solid",
                  borderTopStyle: "solid",
                  borderBottomStyle: "solid",
                  color: "#D6D6D6",
                  backgroundColor: "#424242"
                }),
              },
            }}
          >
            Delete
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "20px",
            fontSize: "12px",
            fontWeight: 500,
            fontFamily: "system-ui",
            maxHeight: "500px",
          }}
        >
          <input
            style={{ width: '100%', fontSize: "14px", padding: "8px", border: '1px solid #000', fontFamily: 'system-ui', justifyContent: 'center', backgroundColor: "#D6D6D6" }}
            type="text"
            placeholder="your text here"
            value={logoName}
            onChange={changeLogoName}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "30px",
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "system-ui",
            maxHeight: "500px",
          }}
        >
          <Select
            size={SIZE.big}
            options={Fonts}
            value={fontValue}
            labelKey="fontFamily"
            valueKey="fontFamily"
            placeholder="Select font"
            maxDropdownHeight="300px"
            onChange={(params) => setFont(params.value)}
            overrides={{
              ControlContainer: {
                style: {
                  fontSize: "14px",
                  borderLeftColor: "black",
                  borderTopColor: "black",
                  borderRightColor: "black",
                  borderBottomColor: "black",
                  borderLeftWidth: "1px",
                  borderRightWidth: "1px",
                  borderTopWidth: "1px",
                  borderBottomWidth: "1px",
                  borderLeftStyle: "solid",
                  borderRightStyle: "solid",
                  borderTopStyle: "solid",
                  borderBottomStyle: "solid",
                  backgroundColor: "#D6D6D6",
                },
              },
            }}
            getOptionLabel={(item) => {
              return <div style={{ fontFamily: `${item.option.fontFamily}` }}>
                {item.option.fontFamily}
              </div>
            }}
            getValueLabel={(item) => {
              return <div style={{ fontFamily: `${item.option.fontFamily}` }}>
                {item.option.fontFamily}
              </div>
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "30px",
            fontSize: "10px",
            fontWeight: 500,
            fontFamily: "system-ui",
          }}
        >
          <label>Text color</label>
          <div
            style={{
              display: "grid",
              grid: "12px / auto auto auto auto auto auto auto auto auto auto auto auto",
              gap: "1px",
            }}
          >
            {colorsList.map((color, index) => (
              <div
                onClick={() => {
                  setFill(color);
                }}
                key={index}
                style={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: color,
                  cursor: "pointer",
                }}
              ></div>
            ))}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "0px",
            fontSize: "10px",
            fontWeight: 500,
            fontFamily: "system-ui",
          }}
        >
          <label> Spacing</label>
          <Slider
            overrides={{
              InnerThumb: () => null,
              //ThumbValue: () => null,
              TickBar: () => null,
              Thumb: {
                style: {
                  height: "20px",
                  width: "20px",
                },
              },
            }}
            min={-100}
            max={500}
            step={10}
            marks={false}
            value={spacingValue}
            onChange={({ value }) => value && setSpacing(value)}
          />
        </div>
      </div>

      <div
        style={{ display: "flex", flexDirection: "column", padding: "10px" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            marginBottom: "30px",
            marginTop: "20px",
            fontSize: "12px",
            fontWeight: 500,
            fontFamily: "system-ui",
          }}
        >
          {editor && !editor.isEditing() ? (
            <Button
              onClick={addText}
              size={SIZE.mini}
              kind={KIND.primary}
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => ({
                    borderColor: "grey",
                    color: "#D6D6D6",
                    borderLeftWidth: "1px",
                    borderRightWidth: "1px",
                    borderTopWidth: "1px",
                    borderBottomWidth: "1px",
                    borderLeftStyle: "solid",
                    borderRightStyle: "solid",
                    borderTopStyle: "solid",
                    borderBottomStyle: "solid",
                    backgroundColor: "transparent",
                  }),
                },
              }}
            >
              Add Text
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Text;
