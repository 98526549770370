import React, { useState, useEffect } from "react";

const VALIDECODE = "free4now";
const VALIDECODE2 = "freenot";


const URL = "https://buy.stripe.com/7sI6or7Sw14BbLifYY";

function PaymentModal({ showPayModal, setShowPayModal, setAvailableDownload }) {
    const [code, setCode] = useState("free4now");
    const [error, setError] = useState(false);

    const handleSubmitCode = () => {

        if (code === VALIDECODE) {
            sessionStorage.setItem("isPaid", true);
            setAvailableDownload(true);
            setShowPayModal(false);
        } else {
            setError(true);
        }

        if (code === VALIDECODE2) {
            sessionStorage.setItem("isPaid", true);
            setAvailableDownload(true);
            setShowPayModal(false);
        } else {
            setError(true);
        }

    }

    const handleSubscribe = () => {
        window.open(URL, '_blank').focus();
    }

    return (
        <div style={{
            zIndex: 100,
            position: 'absolute',
            width: "100%",
            height: "100%",
            display: showPayModal ? "flex" : "none",
            backgroundColor: "#00000088",
            alignItems: 'start',
            justifyContent: 'center',
        }}
        >
            <div style={{
                width: '100%',
                height: '100%',
            }} onClick={() => {setShowPayModal(false)}}></div>
            <div style={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#ffd479',
                border: "0px solid #005493",
                borderRadius: "5px",
                padding: "30px",
            }}>
                <p style={{
                    fontSize: '12pt',
                    fontWeight: '500',
                    fontFamily: 'system-ui',
                    color: '#000'
                }}>
                    Activation code required
                </p>
                <input style={{
                    width: "100%",
                    fontSize: "18pt",
                    borderRadius: "4px",
                    borderWidth: "0px",
                    padding: "9px",
                    textAlign: "center",
                    color: '#4D4D4D',
                    fontFamily: 'system-ui',
                }}
                    value={code}
                    onChange={(e) => {
                        setError(false);
                        setCode(e.target.value);
                    }}
                />
                <p style={{
                    fontSize: '16pt',
                    fontWeight: '400',
                    margin: 0,
                    fontFamily: 'system-ui',
                    color: '#f00',
                    display: error ? "block" : "none"
                }}>
                    Code does not match.
                </p>
                <div style={{
                    width: "100%",
                    margin: '20px 0px',
                    background: '#4F8F00',
                    fontSize: "16pt",
                    borderRadius: "4px",
                    padding: "10px",
                    fontFamily: 'system-ui',
                    color: '#FFF',
                    cursor: 'pointer'
                }}
                onClick={handleSubmitCode}
                >Activate</div>
                <p style={{
                    fontSize: '10pt',
                    fontWeight: '400',
                    margin: 0,
                    fontFamily: 'system-ui',
                    color: '#000'
                }}>
                We keep it free for moment, just press Activate to continue.
                <br />

                </p>
                <br />

                <p style={{
                    fontSize: '16pt',
                    fontWeight: '400',
                    margin: 0,
                    fontFamily: 'system-ui',
                    color: '#000'
                }}>
                <b></b>
                </p>


                <div style={{ marginTop: "50px" }}></div>

            </div>
        </div>

    );
}

export default PaymentModal;
