import { useEffect, useState } from "react";
import useAppContext from "../../../hooks/useAppContext";

function Templates() {
  const { setPage, setTemplate, isMobile } = useAppContext();
  const [type, setType] = useState(2);
  const [templates, setTemplates] = useState([]);
  const typeArr = ["T", "P", "L", "M"];

  useEffect(() => {
    const temp_templates = [];
    for (let t = 0; t < 4; t++) {
      const _templates = [];
      for (let i = 1; i < 100; i++) {
        let num = i < 10 ? `0${i}` : i;
        try {
          let temp = require(`../../../assets/newTemplates/${typeArr[t]}0${num}.sym`);
          _templates.push(temp);
        } catch (err) {

        }
      }
      temp_templates.push(_templates);
    }
    setTemplates(temp_templates);
  }, []);


  const selectTemplate = (item) => {
    fetch(item)
      .then(r => r.text())
      .then(text => {
        setPage("Editor");
        setTemplate(JSON.parse(text));

      });
  }

  return (
    <div
      style={{
        width: "100%",
        height: isMobile ? "100vh" : "1000vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#000000",
        color: "#EBEBEB"
      }}
    >


    <div
      style={{
        display: isMobile ? "flex" : "none",
        flexDirection: "column",
        width: "90%",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#000000",
        margin: "auto"
      }}
    >

    <p
      style={{
        marginBottom: "10px",
        fontSize: "18pt",
        fontWeight: 400,
        textAlign: "center",
        fontFamily: "system-ui",
        color: "#0096FF"
      }}
    >
      <br />
      <br />
      Developed for laptop or desktop
    </p>

    <p
      style={{
        marginBottom: "60px",
        fontSize: "12pt",
        fontWeight: 300,
        textAlign: "left",
        fontFamily: "system-ui",
        color: "#0096FF"
      }}
    >
      * for preview on mobile, use Landscape mode
      <br />
    </p>



    </div>


      <div
        style={{
          display: isMobile ? "none" : "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          width: '60%'
        }}
      >

        <div>

          <p
            style={{
              marginTop: "10px",
              marginBottom: "20px",
              fontSize: "12pt",
              fontWeight: 400,
              textAlign: "center",
              fontFamily: "system-ui",
              color: "#A9A9A9"
            }}
          >

        </p>





          <div style={{ width: '70%', alignItems: "center", margin: "auto", height: '0.5px', minHeight: '0.5px', maxHeight: '0.5px', background: "#595959" }} />
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginLeft: "60px",
            marginRight: "60px",
          }}>





            <p style={{
              marginBottom: "0px",
              fontSize: "14pt",
              fontWeight: 400,
              textAlign: "center",
              fontFamily: "system-ui",
              cursor: 'pointer',
              color:"#737373"
            }} onClick={() => {
                window.open("/index.html", "_self");
              }}
              >
              <big>◀︎</big>
            </p>

            <p style={{
              marginBottom: "0px",
              fontSize: "12pt",
              fontWeight: 400,
              textAlign: "center",
              fontFamily: "system-ui",
              cursor: 'pointer',
              color: type === 2 ? "#E2D8A2" : "#737373"
            }} onClick={() => setType(2)}>
              <big>◉</big>
            </p>


            <p style={{
              marginBottom: "0px",
              fontSize: "12pt",
              fontWeight: 400,
              textAlign: "center",
              fontFamily: "system-ui",
              cursor: 'pointer',
              color: type === 0 ? "#E2D8A2" : "#737373"
            }} onClick={() => setType(0)}>
              <big>◉</big>
            </p>

            <p style={{
              marginBottom: "0px",
              fontSize: "12pt",
              fontWeight: 400,
              textAlign: "center",
              fontFamily: "system-ui",
              cursor: 'pointer',
              color: type === 1 ? "#E2D8A2" : "#737373"
            }} onClick={() => setType(1)}>
              <big>◉</big>
            </p>

            <p style={{
              marginBottom: "0px",
              fontSize: "12pt",
              fontWeight: 400,
              textAlign: "center",
              fontFamily: "system-ui",
              cursor: 'pointer',
              color: type === 3 ? "#E2D8A2" : "#737373"
            }} onClick={() => setType(3)}>
              <big>◉</big>
            </p>



          </div>
          <br />

          <div style={{ width: '70%', alignItems: "center", margin: "auto", height: '0.5px', minHeight: '0.5px', maxHeight: '0.5px', background: "#595959" }} />



          <div style={{
            width: '100%',
            alignItems: "center",
            justifyContent: "center",
            marginTop: "30px"
          }}>
            {templates.length !== 0 && templates[type].map((item, index) => {

              let number = index < 9 ? `0${index + 1}` : `${index + 1}`;
              let image_src = `/images/templates/${typeArr[type]}0${number}.png`;
              return <img
                key={index}
                style={{ width: '100%', padding: '2% 2%', cursor: 'pointer' }}

                onClick={() => { selectTemplate(item) }}
                src={image_src} alt="" />
            })}

<br /><br /><br />
            <div style={{ width: '70%', alignItems: "center", margin: "auto", height: '0.5px', minHeight: '0.5px', maxHeight: '0.5px', background: "#595959" }} />

            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              marginLeft: "60px",
              marginRight: "60px",
            }}>


              <p style={{
                marginBottom: "0px",
                fontSize: "12pt",
                fontWeight: 400,
                textAlign: "center",
                fontFamily: "system-ui",
                cursor: 'pointer',
                color: type === 2 ? "#E2D8A2" : "#737373"
              }} onClick={() => setType(2)}>
                <big>◉</big>
              </p>


              <p style={{
                marginBottom: "0px",
                fontSize: "12pt",
                fontWeight: 400,
                textAlign: "center",
                fontFamily: "system-ui",
                cursor: 'pointer',
                color: type === 0 ? "#E2D8A2" : "#737373"
              }} onClick={() => setType(0)}>
                <big>◉</big>
              </p>

              <p style={{
                marginBottom: "0px",
                fontSize: "12pt",
                fontWeight: 400,
                textAlign: "center",
                fontFamily: "system-ui",
                cursor: 'pointer',
                color: type === 1 ? "#E2D8A2" : "#737373"
              }} onClick={() => setType(1)}>
                <big>◉</big>
              </p>

              <p style={{
                marginBottom: "0px",
                fontSize: "12pt",
                fontWeight: 400,
                textAlign: "center",
                fontFamily: "system-ui",
                cursor: 'pointer',
                color: type === 3 ? "#E2D8A2" : "#737373"
              }} onClick={() => setType(3)}>
                <big>◉</big>
              </p>




            </div><br />

            <div style={{ width: '70%', alignItems: "center", margin: "auto", height: '0.5px', minHeight: '0.5px', maxHeight: '0.5px', background: "#595959" }} />
<br />

<p
  style={{
    marginTop: "60px",
    marginBottom: "40px",
    fontSize: "10pt",
    fontWeight: 400,
    textAlign: "center",
    fontFamily: "system-ui",
    color: "#A9A9A9"
  }}
>
  ...future updates will add more designers to the gallery.
</p>


          </div>
        </div>
      </div>
    </div >
  );
}

export default Templates;
