import React, { useEffect, useState } from "react";
import useAppContext from "../../../../../hooks/useAppContext";
import { Button, KIND, SIZE } from "baseui/button";
import { Slider } from "baseui/slider";
import { Checkbox, STYLE_TYPE } from "baseui/checkbox";
import Icons from "../../../icons";
import { useStyletron } from "baseui";

function Mockup() {
  console.log("Mockup");
  const [css, theme] = useStyletron();
  const { activeSelection, activeEditingModeHandle, editor } = useAppContext();
  const [zoomValue, setZoomValue] = useState([100]);

  useEffect(() => {
    let elements = editor.handlers.canvas.project.activeLayer.children;
    let rasterMatch = elements
      .map((item, index) => elements[elements.length - 1 - index])
      .find((children) => children._class === "Raster");

    if (rasterMatch) {
      let val = rasterMatch.globalMatrix.getA();
      setZoomValue([100 * val]);
    }

  }, [editor]);

  const setBackgroundImage = (path) => {
    setZoomValue([100]);
    editor.setBackgroundImage(path);
    editor.sendToBack();
    editor.discardActiveSelection();
  };

  const setZoom = (value) => {
    setZoomValue(value);
    editor.setBackgroundImageZoom(value[0]);
    editor.sendToBack();
    editor.discardActiveSelection();
  };

  return (
    <div>
      <div style={{
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center'
      }}>








        <div style={{
          display: "flex",
          width: "100%",
          marginBottom: "6px",
          marginTop: "100px",
          textAlign: "center",
          justifyContent: "space-around"
        }}>
          <Icons.MockupPic3 size={16}
            onClick={() => {
              setBackgroundImage('/images/mockup_t-shirt 2.jpg');
              editor.setBackgroundImageZoom(100);
              editor.sendToBack();
              editor.discardActiveSelection();
            }} />
          <Icons.MockupPic9 size={16}
            onClick={() => {
              setBackgroundImage('/images/mockup_t-shirt 2black.jpg');
              editor.setBackgroundImageZoom(100);
              editor.sendToBack();
              editor.discardActiveSelection();
            }} />
          <Icons.MockupPic10 size={16}
            onClick={() => {
              setBackgroundImage('/images/mockup_t-shirt 2blue.jpg');
              editor.setBackgroundImageZoom(100);
              editor.sendToBack();
              editor.discardActiveSelection();
            }} />
          <Icons.MockupPic11 size={16}
            onClick={() => {
              setBackgroundImage('/images/mockup_t-shirt 2red.jpg');
              editor.setBackgroundImageZoom(100);
              editor.sendToBack();
              editor.discardActiveSelection();
            }} />
          <Icons.MockupPic12 size={16}
            onClick={() => {
              setBackgroundImage('/images/mockup_t-shirt 2yellow.jpg');
              editor.setBackgroundImageZoom(100);
              editor.sendToBack();
              editor.discardActiveSelection();
            }} />
        </div>


        <div style={{
          display: "flex",
          width: "100%",
          marginBottom: "8px",
          textAlign: "center",
          justifyContent: "space-around"
        }}>
          <Icons.MockupPic1 size={16}
            onClick={() => {
              setBackgroundImage('/images/mockup_t-shirt 1.jpg');
              editor.setBackgroundImageZoom(100);
              editor.sendToBack();
              editor.discardActiveSelection();
            }} />
          <Icons.MockupPic5 size={16}
            onClick={() => {
              setBackgroundImage('/images/mockup_t-shirt 1black.jpg');
              editor.setBackgroundImageZoom(100);
              editor.sendToBack();
              editor.discardActiveSelection();
            }} />
          <Icons.MockupPic6 size={16}
            onClick={() => {
              setBackgroundImage('/images/mockup_t-shirt 1blue.jpg');
              editor.setBackgroundImageZoom(100);
              editor.sendToBack();
              editor.discardActiveSelection();
            }} />
          <Icons.MockupPic7 size={16}
            onClick={() => {
              setBackgroundImage('/images/mockup_t-shirt 1red.jpg');
              editor.setBackgroundImageZoom(100);
              editor.sendToBack();
              editor.discardActiveSelection();
            }} />
          <Icons.MockupPic8 size={16}
            onClick={() => {
              setBackgroundImage('/images/mockup_t-shirt 1yellow.jpg');
              editor.setBackgroundImageZoom(100);
              editor.sendToBack();
              editor.discardActiveSelection();
            }} />
        </div>

        <div style={{
          width: "100%",
          marginLeft: "0px",
          textAlign: "center",
          marginBottom: "12px",
          marginTop: "15px",
        }}>

          <Icons.MockupEmpty size={16}
            onClick={() => {
              setBackgroundImage('');

        }} />
       </div>


      </div>

    </div >
  );
}

export default Mockup;
