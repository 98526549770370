import React from "react";
import { useStyletron } from "baseui";
import useAppContext from "../../../../hooks/useAppContext";
import Icons from "../../../../interface/components/icons";
function PanelListItem({ label, icon, activePanel }) {
  const { activeMenu, setActiveMenu, setActivePanel, editor, isMobile } = useAppContext();
  const [css] = useStyletron();
  const Icon = label !== "Menu" && Icons[icon];
  const setPanel = (label) => {
    if (isMobile) {
      setActiveMenu(label);
      if (activePanel === "" || activePanel !== label) {
        editor.discardActiveSelection();
        editor.selectFirstElementByType(label);
        setActivePanel(label);
      } else {
        setActivePanel('');
      }
    } else {
      editor.discardActiveSelection();
      editor.selectFirstElementByType(label);
      setActivePanel(label);
    }
  };

  const getLabel = () => {
    if(label === "Layout") {
      return "Canvas"
    } else if(label === "Symmetry") {
      return "Shape"
    } else if(label === "Text") {
      return "Text"
    } else {
      return "Mockup"
    }
  }

  return (
    <div style={{ flexDirection: isMobile ? "column" : "row" }}>
      <div style={{
        display: isMobile ? 'flex' : 'none',
        width: '78px',
        height: '40px',
        justifyContent: 'center',
        alignItems: 'center',

      }}>
        {label === activeMenu && <img
          style={{ width: '40px' }}
          onClick={() => { }}
          src={"/images/DOTS.svg"} alt="" />}
      </div>
      {label === "Menu" && isMobile && <div style={{
        display: 'flex',
        width: '50px',
        height: '50px',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        border: "0.5px solid rgb(138,138,138)",
        backgroundColor: activeMenu === "Menu" ? "#333333" : "rgb(232, 232, 232)",
      }}

        onClick={() => setPanel(label)}
      >
        <img
          style={{ width: '40px' }}
          src={"/images/MENU.svg"} alt="" />
      </div>}
      {label !== "Menu" && <div
        onClick={() => setPanel(label)}
        className={css({
          width: "50px",
          height: "50px",
          backgroundColor:
            isMobile
              ? label === activeMenu
                ? label === activePanel
                  ? "#333333"
                  : "rgb(192,214,214)"
                : "rgb(232,232,232)"
              : label === activePanel

              //BACKGROUND BUTTONS
                              ? "#424242"
                              : "#797979",

          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          fontSize: "10px",
          fontWeight: 500,
          fontFamily: "system-ui",
          userSelect: "none",
          transition: "all 0.3s",
          border: isMobile ? "0.5px solid rgb(0, 0, 0)" : "0px solid rgb(138,138,138)",
          borderBottom: "0.5px solid rgb(169,169,169) !important",

          gap: "0.4rem",
          ":hover": {
            cursor: "pointer",
            backgroundColor: "#424242",
            transition: "all 0.3s",
          },
        })}
      >
        <Icon size={14} />
        <div
          style={{
            //iconcolor
            color: isMobile && label === activeMenu && label === activePanel ? "grey" : "white"
          }}
        >{getLabel()}</div>
      </div>}
    </div>
  );
}

export default PanelListItem;
