import React, { useEffect, useContext, useRef, useState } from "react";
import Editor from "./Editor";
import useAppContext from "../hooks/useAppContext";
import { AppContext } from "../contexts/AppContext";

function Canvas({ aboutIsOpen, closeAboutModal }) {
  const [isShownAbout, setIsShownAbout] = useState(false);
  const containerRef = useRef();
  const canvasRef = useRef();
  const { template, setEditor } = useAppContext();
  const context = useContext(AppContext);
  useEffect(() => {
    const container = containerRef.current;
    const { clientHeight, clientWidth } = container;
    const editor = new Editor({
      canvasRef: canvasRef.current,
      context: context,
      template: template,
    });
    setEditor(editor);
    editor.canvas.resize(container.clientWidth, container.clientHeight);
    const resizeObserver = new ResizeObserver((entries) => {
      const { width = clientWidth, height = clientHeight } =
        (entries[0] && entries[0].contentRect) || {};
      editor.canvas.resize(width, height);
    });
    resizeObserver.observe(container);
    return () => {
      editor.destroy();
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsShownAbout(aboutIsOpen);
  }, [aboutIsOpen]);

  return (
    <div
      id="editor-container"
      ref={containerRef}
      style={{
        flex: 1,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          opacity: isShownAbout ? 1 : 1
        }}
      >
        <canvas id="canvas" ref={canvasRef}></canvas>
      </div>
      {isShownAbout && <div
        style={{
          position: "absolute",
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '30%',
            height: '60%',
            paddingLeft: '10px',
            paddingRight: '10px',
            border: 'solid 0.5px #A9A9A9',
            borderRadius: "10px",
            overflow: 'auto',
            background: 'rgb(0,0,0)'
          }}>
          <div
            style={{
              alignSelf: 'flex-end',
              cursor: "pointer"
            }}
            onClick={() => closeAboutModal()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="#A9A9A9"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
          <p style={{ alignSelf: 'center', margin: 0, fontSize: '14px', fontFamily: "system-ui", color:"#A9A9A9",}}><b>sketch-app.com</b></p>
          <p style={{ alignSelf: 'center', margin: 0, fontSize: '10px', fontFamily: "system-ui", color:"#A9A9A9",}}>version 1.1.2024.build 1</p>




          <p style={{ margin: '10px 0px', fontSize: '14px', fontFamily: "system-ui", }}>
          <br />
          <br />
          <div style={{ width: '100%', height: '1px', minHeight: '1px', maxHeight: '1px', backgroundColor: '#5E5E5E' }} >
          </div>
          <br />
          <a href="https://www.instagram.com/logosketchapp/" target="_blank">
          <img
            style={{ width: '15%' }}
            src={"/images/INSTAGRAM-y.svg"} alt="" />
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="https://www.facebook.com/profile.php?id=61564868260090" target="_blank">
          <img
            style={{ width: '15%' }}
            src={"/images/FACEBOOK-y.svg"} alt="" />
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="https://youtube.com/@sketch-app.com-editor?si=NOZ0-9gJw4MOfgxw" target="_blank">
          <img
            style={{ width: '15%' }}
            src={"/images/YOUTUBE-y.svg"} alt="" />
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="mailto:mugur.neacsu@icloud.com" target="_blank">
          <img
            style={{ width: '15%' }}
            src={"/images/EMAIL-y.svg"} alt="" />
          </a>

          <br/>
          </p>




          <div style={{ width: '100%', height: '1px', minHeight: '1px', maxHeight: '1px', backgroundColor: '#5E5E5E' }} >
          </div>
          <p style={{ alignSelf: 'center', margin: '60px 0px', fontSize: '10px', textAlign: 'center', fontFamily: "system-ui", color:"#A9A9A9",}}>
            sketch-app.com is a registered domain of:<br /><br />
            NPM PFA Commercial registration number: 26324167<br />
            Company address: Bucharest, sector 4, str. Huedin nr 6,bloc M 1/2, scara 4, etaj1, ap.138, cam1.<br />
            Bucharest - Romania.
          </p>
        </div>
      </div>}
    </div>
  );
}
export default Canvas;
